import { createThemeApp } from "../../../theme";

export const createToogleThemeMode = (currentState) => {
    let { theme } = currentState;
    let { mode } = theme;

    let newThemeMode = mode === 'light' ? 'dark' : 'light'
    localStorage.setItem("_theme_mode_", newThemeMode)

    return {
        ...currentState,
        theme: { currentTheme: createThemeApp(newThemeMode), mode: newThemeMode }
    }
}

export const initialStateTheme = () => {

    let themeMode = localStorage.getItem("_theme_mode_") || 'light'

    return {
        currentTheme: createThemeApp(themeMode),
        mode: themeMode //or dark
    }
}