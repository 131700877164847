


export const ThemeFonts = {
  fontFamily: [
    'montserrat',
    "Roboto", 
    "Helvetica", 
    "Arial", 
    "sans-serif"
  ].join(','),
  h1: {
    fontWeight: 500,
    fontSize: '35px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '29px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '24px',
  },
  h4: {
    fontWeight: 500,
    fontSize: '20px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
  },
  h6: {
    fontWeight: 500,
    fontSize: '14px',
  },
  subtitle1: {
    fontSize: '16px',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '14px',
  },
  body1: {
    fontSize: '14px',
  },
  body2: {
    fontSize: '12px',
  },
  button: {
    fontSize: '14px'
  },
  caption: {
    fontSize: '11px',
  },
  overline: {
    fontSize: '11px',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
};