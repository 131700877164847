import { roundToUp } from "round-to";
import { formatNumber, formatNumberToMM } from "../../../utils/number"
import { isMobile } from "react-device-detect";


function sumSaldo(data, financeiro) {

    const total = data?.filter(e => e.financeiro == financeiro)?.reduce((sum, financial) => {
        const saleTotal = parseFloat(financial.saldo);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

function sumValue(data, financeiro) {

    const total = data?.filter(e => e.financeiro == financeiro)?.reduce((sum, financial) => {
        const saleTotal = parseFloat(financial.valor);
        return sum + saleTotal;
    }, 0);

    return total || 0;
}

function sumSaldoToDate(data, targetDate, financeiro) {
    let sum = 0;

    data?.filter(e => e.financeiro == financeiro && e.dia <= targetDate)?.forEach((financial) => {

        sum += parseFloat(financial.saldo);

    });

    return sum;
}

export const FinancialPendingCreditValueKPI = (kpiFinancialPending) => {

    const {
        currentMonthFinancialPending,
    } = kpiFinancialPending || {};


    const existsCurrentMonth = currentMonthFinancialPending && currentMonthFinancialPending.length;
    

    let result = {
        caption: 'A Receber',
        metric: 0,
        progress: 0,
        target: 0,
        color: 'green',
    }


    if (!existsCurrentMonth) {
        return result
    }

    const currentMonthSaldo = sumSaldo(currentMonthFinancialPending, 1);
    result.metric = isMobile ? formatNumberToMM(currentMonthSaldo) : formatNumber(currentMonthSaldo, {style: 'currency'})

    const currentMonthValor = sumValue(currentMonthFinancialPending, 1);
    
    result.target = isMobile ? formatNumberToMM(currentMonthValor) : formatNumber(currentMonthValor, {style: 'currency'})

    const amountReceived = currentMonthValor - currentMonthSaldo
    
    if (amountReceived) {
        result.valueProgress = isMobile ? formatNumberToMM(amountReceived) : formatNumber(amountReceived, {style: 'currency'})
    }

    result.progress = roundToUp(((amountReceived / currentMonthValor)) * 100, 2)
    // result.badgeDelta = roundToUp((currentMonthSaldo / currentMonthValor) * 100, 2)

    return result
}

export const FinancialPendingDebitValueKPI = (kpiFinancialPending) => {

    const {
        currentMonthFinancialPending,
    } = kpiFinancialPending || {};


    const existsCurrentMonth = currentMonthFinancialPending && currentMonthFinancialPending.length;
    

    let result = {
        caption: 'A Pagar',
        metric: 0,
        progress: 0,
        target: 0,
        color: 'red',
    }


    if (!existsCurrentMonth) {
        return result
    }

    const currentMonthSaldo = sumSaldo(currentMonthFinancialPending, -1);
    result.metric = isMobile ? formatNumberToMM(currentMonthSaldo) : formatNumber(currentMonthSaldo, {style: 'currency'})

    const currentMonthValor = sumValue(currentMonthFinancialPending, -1);
    
    result.target = isMobile ? formatNumberToMM(currentMonthValor) : formatNumber(currentMonthValor, {style: 'currency'})

    const amountReceived = currentMonthValor - currentMonthSaldo
    
    if (amountReceived) {
        result.valueProgress = isMobile ? formatNumberToMM(amountReceived) : formatNumber(amountReceived, {style: 'currency'})
    }

    result.progress = roundToUp(((amountReceived / currentMonthValor)) * 100, 2)
    // result.badgeDelta = roundToUp((currentMonthSaldo / currentMonthValor) * 100, 2)

    return result
}

export const FinancialReceivedCreditValueKPI = (kpiFinancialNotPending) => {

    const {
        currentMonthFinancialPending,
    } = kpiFinancialNotPending || {};

    const existsCurrentMonth = currentMonthFinancialPending && currentMonthFinancialPending.length;

    let result = {
        caption: 'Recebido',
        metric: 0,
        progress: 0,
        target: 0,
        color: 'green',
    }

    if (!existsCurrentMonth) {
        return result
    }

    const currentMonthSaldo = sumSaldo(currentMonthFinancialPending, 1);
    result.metric = isMobile ? formatNumberToMM(currentMonthSaldo) : formatNumber(currentMonthSaldo, {style: 'currency'})

    const currentMonthValor = sumValue(currentMonthFinancialPending, 1);
    
    result.target = isMobile ? formatNumberToMM(currentMonthValor) : formatNumber(currentMonthValor, {style: 'currency'})

    const amountReceived = currentMonthValor - currentMonthSaldo
    
    if (amountReceived) {
        result.valueProgress = isMobile ? formatNumberToMM(amountReceived) : formatNumber(amountReceived, {style: 'currency'})
    }

    result.progress = roundToUp(((amountReceived / currentMonthValor)) * 100, 2)
    // result.badgeDelta = roundToUp((currentMonthSaldo / currentMonthValor) * 100, 2)

    return result
}

export const FinancialPaidDebitValueKPI = (kpiFinancialNotPending) => {

    const {
        currentMonthFinancialNotPending,
    } = kpiFinancialNotPending || {};


    const existsCurrentMonth = currentMonthFinancialNotPending && currentMonthFinancialNotPending.length;
    

    let result = {
        caption: 'Pago',
        metric: 0,
        progress: 0,
        target: 0,
        color: 'red',
    }


    if (!existsCurrentMonth) {
        return result
    }

    const currentMonthSaldo = sumSaldo(currentMonthFinancialNotPending, -1);
    result.target = isMobile ? formatNumberToMM(currentMonthSaldo) : formatNumber(currentMonthSaldo, {style: 'currency'})

    const currentMonthValor = sumValue(currentMonthFinancialNotPending, -1);
    
    result.metric = isMobile ? formatNumberToMM(currentMonthValor) : formatNumber(currentMonthValor, {style: 'currency'})

    const amountReceived = currentMonthValor - currentMonthSaldo
    
    if (amountReceived) {
        result.valueProgress = isMobile ? formatNumberToMM(amountReceived) : formatNumber(amountReceived, {style: 'currency'})
    }

    result.progress = roundToUp(((amountReceived / currentMonthValor)) * 100, 2)
    // result.badgeDelta = roundToUp((currentMonthSaldo / currentMonthValor) * 100, 2)

    return result

    
}

