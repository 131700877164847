import { roundToUp } from "round-to"
import { formatNumber, formatNumberToMM } from "../../../utils/number"
import { isMobile } from "react-device-detect";
import { countOfOrdersToDate, filterRecordsByDate, getSalesTotalDate, getSalesTotalUntilDate, groupByDate, groupByHour, groupByImportType, groupByTypeOrder, groupByWeek, months, sumTotalValue, sumValueOfOrdersToDate, weeks } from "../../../utils/helper";

/**
 * 
 * @param {*} sales 
 * @param {*} lastSales 
 * @returns { caption, metric, badgeDelta, progress, target, deltaType }
 *  
 */
export const orderCountKPI = (sales) => {
    const currentMonthSales = sales?.currentMonthSales || [];
    const lastMonthSales = sales?.lastMonthSales || [];
    
    let result = {
        caption: 'Pedidos',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease',
    };

    const lengthCurrentSales = currentMonthSales.length;

    // Retorna resultado inicial se não há vendas do mês atual
    if (lengthCurrentSales === 0) {
        return result;
    }

    result.metric = isMobile 
        ? formatNumberToMM(lengthCurrentSales) 
        : formatNumber(lengthCurrentSales, 'decimal');

    const lengthLastSales = lastMonthSales.length;

    if (lengthLastSales > 0) {
        result.target = isMobile 
            ? formatNumberToMM(lengthLastSales) 
            : formatNumber(lengthLastSales, 'decimal');

        result.progress = roundToUp((lengthCurrentSales / lengthLastSales) * 100, 2);
        result.badgeDelta = roundToUp(((lengthCurrentSales - lengthLastSales) / lengthLastSales) * 100, 2);

        // Calcula percentual considerando vendas do mês anterior até a data de "hoje"
        if (sales.isCurrentMonth) {
            const salesByToday = countOfOrdersToDate(lastMonthSales, sales.currentDay);
            result.badgeDelta = roundToUp(((lengthCurrentSales - salesByToday) / salesByToday) * 100, 2);

            result.progress = roundToUp((lengthCurrentSales / salesByToday) * 100, 2);
        }
    }

    // console.log('orderCountKPI - result', lengthCurrentSales, sales.currentDay, sales.isCurrentMonth);
    return result;
}

export const orderTotalKPI = (sales) => {
    const currentMonthSales = sales?.currentMonthSales || [];
    const lastMonthSales = sales?.lastMonthSales || [];

    let result = {
        caption: 'Valor total',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease',
    };

    const currentMonthValue = sumTotalValue(currentMonthSales);

    // Retorna resultado inicial se não há vendas do mês atual
    if (currentMonthSales.length === 0) {
        return result;
    }

    result.metric = isMobile 
        ? formatNumberToMM(currentMonthValue) 
        : formatNumber(currentMonthValue, { style: 'currency' });

    if (lastMonthSales.length > 0) {
        const lastMonthValue = sumTotalValue(lastMonthSales);
        result.target = isMobile 
            ? formatNumberToMM(lastMonthValue) 
            : formatNumber(lastMonthValue, { style: 'currency' });

        result.progress = roundToUp((currentMonthValue / lastMonthValue) * 100, 2);
        result.badgeDelta = roundToUp(((currentMonthValue - lastMonthValue) / lastMonthValue) * 100, 2);

        // Calcula percentual considerando vendas do mês anterior até a data de "hoje"
        if (sales.isCurrentMonth) {
            const salesByToday = sumValueOfOrdersToDate(lastMonthSales, sales.currentDay);
            
            if (salesByToday > 0) {
                result.badgeDelta = roundToUp(((currentMonthValue - salesByToday) / salesByToday) * 100, 2);
                result.progress = roundToUp((currentMonthValue / salesByToday) * 100, 2);
            }
        }
    }

    // console.log('orderTotalKPI - result', result);
    return result;
}

export const orderAverageTicketKPI = (sales) => {
    const currentMonthSales = sales?.currentMonthSales || [];
    const lastMonthSales = sales?.lastMonthSales || [];

    let result = {
        caption: 'Ticket médio',
        metric: 0,
        badgeDelta: 0,
        progress: 0,
        target: 0,
        deltaType: 'moderateIncrease',
    };

    // Retorna resultado inicial se não há vendas do mês atual
    if (currentMonthSales.length === 0) {
        return result;
    }

    const currentMonthLength = currentMonthSales.length;
    const currentMonthValue = sumTotalValue(currentMonthSales);
    const currentMetric = currentMonthValue / currentMonthLength;

    result.metric = formatNumber(roundToUp(currentMetric, 2), { style: 'currency' });

    if (lastMonthSales.length > 0) {
        const lastMonthLength = lastMonthSales.length;
        const lastMonthValue = sumTotalValue(lastMonthSales);
        const currentTarget = lastMonthValue / lastMonthLength;

        result.target = formatNumber(roundToUp(currentTarget, 2), { style: 'currency' });
        result.badgeDelta = roundToUp(((currentMetric - currentTarget) / currentTarget) * 100, 2);
        result.progress = roundToUp((currentMetric / currentTarget) * 100, 2);
    }

    // Calcula percentual considerando vendas do mês anterior até a data de "hoje"
    if (sales.isCurrentMonth) {
        const salesByTodayValue = sumValueOfOrdersToDate(lastMonthSales, sales.currentDay);

        if (salesByTodayValue > 0) {
            const salesByTodayLength = countOfOrdersToDate(lastMonthSales, sales.currentDay);
            const salesByTodayTarget = salesByTodayValue / salesByTodayLength;
            
            result.badgeDelta = roundToUp(((currentMetric - salesByTodayTarget) / salesByTodayTarget) * 100, 2);
            result.progress = roundToUp((currentMetric / salesByTodayTarget) * 100, 2);
        }
    }

    // console.log('orderAverageTicketKPI - result', result);
    return result;
}

export const cumulativeSalesPerDateKPI = (sales) => {
    const currentMonthSales = sales?.currentMonthSales || [];

    let result = {
        caption: 'Vendas acumuladas por dia',
        data: [],
        index: "date",
        categories: [],
        colors: ["indigo", "cyan", "emerald"],
    };

    // Retorna resultado inicial se não há vendas do mês atual
    if (currentMonthSales.length === 0) {
        return result;
    }

    const actualMonth = sales.currentMonth - 1;
    const lastMonth = (actualMonth === 0) ? 11 : actualMonth - 1;

    const currentMonthDescription = months[actualMonth];
    const lastMonthDescription = months[lastMonth];
    const currentMonthSalesByDay = `Vendas diárias de ${currentMonthDescription}`;

    result.categories.push(lastMonthDescription, currentMonthDescription, currentMonthSalesByDay);

    const groupedDataCurrentMonth = groupByDate(currentMonthSales);
    const groupedDataLastMonth = groupByDate(sales.lastMonthSales);

    const chartData = Array.from({ length: 31 }, (_, day) => {
        const dayIndex = day + 1; // Para dias de 1 a 31
        return {
            date: dayIndex,
            [lastMonthDescription]: getSalesTotalUntilDate(groupedDataLastMonth, dayIndex),
            [currentMonthDescription]: getSalesTotalUntilDate(groupedDataCurrentMonth, dayIndex),
            [currentMonthSalesByDay]: getSalesTotalDate(groupedDataCurrentMonth, dayIndex),
        };
    });

    result.data = chartData;

    return result;
}


export const cumulativeSalesPerWeekKPI = (sales) => {
    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length;

    let result = {
        caption: 'Vendas por dia da semana',
        data: [],
        index: "week",
        categories: [],
        colors: ["indigo", "cyan"],
    }

    if (!existsCurrentMonthSales) {
        return result;
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1;

    if (lastMonth < 0) {
        lastMonth = 11;
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription);
    
    const groupedDataCurrentMonth = groupByWeek(sales.currentMonthSales);
    
    let groupedDataLastMonth

    //Filtra os registros até a data atual caso seja o mês atual
    if ((!sales.compareWith || sales.compareWith === 'CURRENT_DATE') && sales.isCurrentMonth) {
        groupedDataLastMonth = groupByWeek(filterRecordsByDate(sales.lastMonthSales, sales.currentDay)); 
    } else {
        groupedDataLastMonth = groupByWeek(sales.lastMonthSales)
    }

    function filterByWeek(data, week) {
        return data?.find(item => item?.week === week);
    }

    let chartData = [];

    for (let week = 0; week <= 6; week++) {
        chartData.push({
            week: weeks[week],
            [lastMonthDescription]: sumTotalValue(filterByWeek(groupedDataLastMonth, week)?.records),
            [currentMonthDescription]: sumTotalValue(filterByWeek(groupedDataCurrentMonth, week)?.records),
        });
    }

    result.data = chartData;

    return result;
}


export const cumulativeSalesPerTypeOrderKPI = (sales) => {
    
    let result = {
        caption: 'Vendas por tipo de pedido',
        data: [],
        index: "typeOrder",
        categories: [],
        colors: ["indigo", "cyan"],
        // layout: 'vertical',
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    
    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByTypeOrder(sales.currentMonthSales)
   
    let groupedDataLastMonth

    //Filtra os registros até a data atual caso seja o mês atual
    if ((!sales.compareWith || sales.compareWith === 'CURRENT_DATE') && sales.isCurrentMonth) {
        groupedDataLastMonth = groupByTypeOrder(filterRecordsByDate(sales.lastMonthSales, sales.currentDay));
    } else {
        groupedDataLastMonth = groupByTypeOrder(sales.lastMonthSales)
    }

    function filterByTypeOrder(data, typeOrder) {
        return data?.find(item => item?.typeOrder?.toUpperCase() === typeOrder?.toUpperCase());
    }

    function extractTypeOrders(data) {
        const typeOrders = data.map(item => item.typeOrder || 'Comanda');
        return typeOrders.filter((value, index) => typeOrders.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    
    const orderTypeList = extractTypeOrders(groupedData)
    
    let chartData = [];

    orderTypeList.forEach(type => {
        chartData.push({
            typeOrder: type,
            [lastMonthDescription]: sumTotalValue(filterByTypeOrder(groupedDataLastMonth, type)?.records),
            [currentMonthDescription]: sumTotalValue(filterByTypeOrder(groupedDataCurrentMonth, type)?.records),
        });
    })

    result.data = chartData

    // console.log('-> result', result)

    return result
}

export const salesPerHourKPI = (sales) => {
    let result = {
        caption: 'Vendas por hora',
        data: [],
        index: "hour",
        categories: [],
        colors: ["indigo", "cyan"],
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length
    
    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByHour(sales.currentMonthSales)
    
    let groupedDataLastMonth

    //Filtra os registros até a data atual caso seja o mês atual
    if ((!sales.compareWith || sales.compareWith === 'CURRENT_DATE') && sales.isCurrentMonth) {
        groupedDataLastMonth = groupByHour(filterRecordsByDate(sales.lastMonthSales, sales.currentDay));
    } else {
        groupedDataLastMonth = groupByHour(sales.lastMonthSales)
    }

    function filterByHour(data, hour) {
        return data?.find(item => item?.hour === hour);
    }

    let chartData = [];

    for (let hour = 0; hour <= 23; hour++) {
        chartData.push({
            hour,
            [lastMonthDescription]: sumTotalValue(filterByHour(groupedDataLastMonth, hour)?.records),
            [currentMonthDescription]: sumTotalValue(filterByHour(groupedDataCurrentMonth, hour)?.records),
        });
    }

    result.data = chartData

    // console.log('salesPerHourKPI -> result', result)

    return result
}

export const salesPerImportTypeKPI = (sales) => {
    let result = {
        caption: 'Vendas por canal',
        data: [],
        layout: 'vertical',
        index: "typeOrder",
        categories: [],
        colors: ["indigo", "cyan"],
        yAxisWidth: 90,
    }

    const existsCurrentMonthSales = sales && sales.currentMonthSales && sales.currentMonthSales.length

    if (!existsCurrentMonthSales) {
        return result
    }

    const actualMonth = sales.currentMonth - 1;
    let lastMonth = actualMonth - 1
    
    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByImportType(sales.currentMonthSales)

    let groupedDataLastMonth

    //Filtra os registros até a data atual caso seja o mês atual
    if ((!sales.compareWith || sales.compareWith === 'CURRENT_DATE') && sales.isCurrentMonth) {
        groupedDataLastMonth = groupByImportType(filterRecordsByDate(sales.lastMonthSales, sales.currentDay)); 
    } else {
        groupedDataLastMonth = groupByImportType(sales.lastMonthSales)
    }

    function filterByImportType(data, importType) {
        return data?.find(item => item?.importType?.toUpperCase() === importType?.toUpperCase());
    }

    function extractImportType(data) {
        const importTypes = data.map(item => item.importType?.toUpperCase() || 'Comanda');

        return importTypes.filter((value, index) => importTypes.indexOf(value?.toUpperCase()) === index);
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    const importTypeList = extractImportType(groupedData)

    let chartData = [];

    importTypeList.forEach(type => {
        chartData.push({
            typeOrder: type,
            [lastMonthDescription]: sumTotalValue(filterByImportType(groupedDataLastMonth, type)?.records),
            [currentMonthDescription]: sumTotalValue(filterByImportType(groupedDataCurrentMonth, type)?.records),
        });
    })

    result.data = chartData

    return result
}
